import { SearchOrder } from "utils/enums/SearchOrder";
import { TKEvent } from "utils/enums/TKEvent";
import { EventAggregator } from "aurelia-event-aggregator";
import { AppConfig } from "app-config";
import { I18N } from "aurelia-i18n";
import { SearchType } from "utils/enums/SearchType";
import { Utils } from "utils/helpers/utils";
import { IResult } from "domain/IResult";
import { IProduct } from "domain/Product/IProduct";
import { Router } from "aurelia-router";
import { LogManager, autoinject, observable } from "aurelia-framework";
import { SearchService } from "services/product/search-service";

export const log = LogManager.getLogger("app.search.index");

@autoinject
export class Index {
  carPlateRegex = /^([\d]{4}[A-Z]{2}|[\d]{3}[A-Z]{3}|[\d]{3}[A-Z]{1}|[\d]{2}[A-Z]{2,3}|[A-Z]{1,8}[\d]{1,8})$/i;
  standardCarPlateRegex = /^([\d]{3}[A-Z]{3})$/i;
  vinRegex = /^([\dA-Z]{17})$/gi;

  query: string;
  @observable currentPage = 1;
  type: SearchType;
  @observable orderBy: SearchOrder = SearchOrder.Default;
  @observable orderReversed = false;

  result: IResult<IProduct> | undefined;
  error: string | undefined;
  previousSearch?: string[];

  carQueryType = "";
  carQuery = "";

  // Defaults
  options = {
    showAmount: false,
    showSum: false,
    showAddToCart: true,
    showRemoveFromCart: false,
  };
  defaultResult: IResult<IProduct> = {
    items: [],
    currentPage: 1,
    lastPage: 1,
  };

  constructor(
    private searchService: SearchService,
    private router: Router,
    private i18n: I18N,
    private appConfig: AppConfig,
    private eventAggregator: EventAggregator
  ) {
    this.eventAggregator.subscribeOnce(TKEvent.userSettingsLoaded, () => {
      if (this.type === undefined)
        this.type = this.appConfig.userSettings.defaultSearchLimitation;

      this.loadResult();
    });
    this.eventAggregator.subscribe(TKEvent.localeChanged, () =>
      this.loadResult()
    );
  }

  activate(parameters: any) {
    const query = decodeURIComponent(parameters.query);

    if (query.length < 3) {
      this.error = this.i18n.tr("pages.search.errors.short-query");
      return;
    }

    // Clear the old result so the loading icon could activate again
    this.result = undefined;

    // We want to make sure they are definitely numbers
    const page = parseInt(parameters.page) || 1;
    const type = parseInt(parameters.type) || SearchType.All;
    const orderBy = parseInt(parameters.orderBy) || SearchOrder.Default;
    const orderReversed = parameters.orderReversed == "true";

    // Don't query results twice - a different search has already been initiated
    if (
      query == this.query &&
      page == this.currentPage &&
      type == this.type &&
      orderBy == this.orderBy &&
      orderReversed == this.orderReversed
    )
      return;

    this.query = query;
    this.currentPage = page;
    this.type = type;
    this.orderBy = orderBy;
    this.orderReversed = orderReversed;

    this.loadResult();
  }

  currentPageChanged(newPage: number, oldPage: number) {
    // Required values haven't been set yet
    if (oldPage === undefined || this.type === undefined) return;

    this.search(this.query, newPage, this.type, this.orderBy, this.orderReversed);
  }

  orderByChanged(newOrderBy: SearchOrder, oldOrderBy: SearchOrder) {
    if (
      oldOrderBy === undefined ||
      this.orderBy === undefined ||
      this.query === undefined
    )
      return;

    this.search(this.query, this.currentPage, this.type, newOrderBy, this.orderReversed)
  }

  orderReversedChanged(newOrderReversed: boolean) {
    if (this.query === undefined) return;

    this.search(this.query, this.currentPage, this.type, this.orderBy, newOrderReversed)
  }

  loadResult() {
    if (this.query === undefined) return;

    this.result = undefined;
    this.error = undefined;

    // Check search query for VIN or licence plate formats
    const isQueryVin = this.vinRegex.test(this.query);
    const isQueryPlate = this.carPlateRegex.test(this.query);
    const isPlateStandard = this.standardCarPlateRegex.test(this.query);

    // Show car search button if the query is in certain format
    if (isQueryVin || isQueryPlate) {
      this.carQueryType = isQueryVin
        ? this.i18n.tr("pages.search.fields.query-type.vin")
        : this.i18n.tr("pages.search.fields.query-type.plate");
      this.carQuery = this.query.toUpperCase();
    }

    const currentSearch = this.getCurrentSearch()
    if (!this.appConfig.userSettings || this.isSameAsPreviousSearch(currentSearch)) return;

    this.makeSearchRequest(isPlateStandard);
  }

  // #region HELPERS

  search(query: string, page: number, type: SearchType, orderBy: SearchOrder, orderReversed: boolean) {
    this.router.navigateToRoute("searchResult", {
      query: Utils.encode(query),
      page,
      type,
      orderBy,
      orderReversed,
    }, {
      trigger: false,
      replace: true,
    });

    this.loadResult();
  }

  makeSearchRequest(isPlateStandard: boolean) {
    this.saveNewPreviousSearch();
    const currentSearch = this.getCurrentSearch();

    this.searchService
      .fetchResult(
        Utils.encode(this.query),
        this.currentPage,
        this.type,
        this.orderBy,
        this.orderReversed
      )
      .then((result) => {
        // Don't overwrite new search results
        if (!this.isSameAsPreviousSearch(currentSearch)) return;

        this.result = result ?? this.defaultResult;

        // Run the car search automatically when no products were found and query is in standard licence plate format
        if (this.result.items.length == 0 && isPlateStandard) {
          Utils.showInfoToast(this.i18n.tr("pages.search.info.no-items-found"));
          this.openCarSearch();
          return;
        }

        this.currentPage = parseInt(result.currentPage as any);
      })
      .catch((error) => {
        this.previousSearch = undefined;
        this.error = Utils.getErrorMessage(error, this.i18n);
      });
  }

  openCarSearch() {
    this.router.navigateToRoute("carVehiclesQuery", {
      query: Utils.encode(this.query),
    });
  }

  isSameAsPreviousSearch(currentSearch: string[] | undefined): boolean {
    if (!this.previousSearch || !currentSearch) return false;
  
    const [prevQuery, prevPage, prevType, prevOrderBy, prevOrderReversed] = this.previousSearch;
    const [thisQuery, thisPage, thisType, thisOrderBy, thisOrderReversed] = currentSearch;

    return prevQuery == thisQuery &&
           prevPage == thisPage &&
           prevType == thisType &&
           prevOrderBy == thisOrderBy &&
           prevOrderReversed == thisOrderReversed;
  }

  saveNewPreviousSearch() {
    this.previousSearch = this.getCurrentSearch();
  }

  getCurrentSearch() {
    return [
      Utils.encode(this.query),
      this.currentPage.toString(),
      this.type.toString(),
      this.orderBy.toString(),
      this.orderReversed.toString(),
    ];
  }

  // #endregion
}
